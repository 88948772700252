<template>
  <v-container>
    <div>
      <v-row>
        <v-col cols="8">
          <ModuleInput
            :info="{
              description:
                'A compound-induced gene expression signature (CSV format)',
              type: 'file',
            }"
            filled
            dense
            accept=".csv"
            name="input"
            @setFile="onSetFile"
          />
          <v-row no-gutters justify="end">
            <sample-download-button v-if="hasSampleFile" :filename="config.sample_file" />
          </v-row>
        </v-col>
      </v-row>
      <h3>Threshold for selecting regulated genes</h3>
      <v-row>
        <v-col cols="3">
          <v-select
            v-model="upRegulatedGenes"
            item-text="text"
            item-value="value"
            :items="settings"
            label="Up-regulated genes: "
            outlined
            dense
          />
        </v-col>
        <v-col v-show="upRegulatedGenes==='manual-percent'" cols="4">
          <ModuleInput
            :info="{ description: 'Top (percent)', type: 'float' }"
            name="top"
            dense
            @changeParameter="onChange"
          />
        </v-col>
        <v-col v-show="upRegulatedGenes==='manual-value'" cols="4">
          <ModuleInput
            :info="{
              description: 'Higher than',
              type: 'float',
            }"
            name="higherThan"
            dense
            @changeParameter="onChange"
          />
        </v-col>
        <v-col v-show="upRegulatedGenes==='default'" offset="1" cols="4">
          <p class="text--secondary">
            <span>
              Default: <b>Top (percent)</b> will be set to
              <b>5%</b>.
            </span>
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3">
          <v-select
            v-model="downRegulatedGenes"
            item-text="text"
            item-value="value"
            :items="settings"
            label="Down-regulated genes: "
            outlined
            dense
          />
        </v-col>
        <v-col v-show="downRegulatedGenes==='manual-percent'" cols="4">
          <ModuleInput
            :info="{
              description: 'Bottom (percent)',
              type: 'float',
            }"
            name="bottom"
            dense
            @changeParameter="onChange"
          />
        </v-col>
        <v-col v-show="downRegulatedGenes==='manual-value'" cols="4">
          <ModuleInput
            :info="{
              description: 'Lower than',
              type: 'float',
            }"
            name="lowerThan"
            dense
            @changeParameter="onChange"
          />
        </v-col>
        <v-col v-show="downRegulatedGenes==='default'" offset="1" cols="4">
          <p class="text--secondary">
            <span>
              Default: <b>Bottom (percent)</b> will be set to
              <b>5%</b>.
            </span>
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <v-select
            v-model="geneAnnotation"
            :items="geneAnnotations"
            label="Gene Annotation"
            required
            :rules="[v => !!v || 'The type of the input is required']"
          />
        </v-col>
      </v-row>

      <ExecuteButton
        :disabled="!canSubmit"
        :validation-method="validate"
        :confirm-loading="loading"
        @confirm="onSubmit"
      />
    </div>
  </v-container>
</template>

<script>
import Swal from 'sweetalert2';
import router from '@/router';
import ModuleInput from '@/components/ModuleInput';
import ExecuteButton from '@/components/ExecuteButton.vue';
import SampleDownloadButton from '@/components/SampleDownloadButton.vue';

export default {
  name: 'PathwayPredictionExecuteView',
  components: {
    ModuleInput,
    ExecuteButton,
    SampleDownloadButton
  },
  data() {
    return {
      id: this.$route.params.id,
      config: Object(),
      parameters: Object(),
      files: Object(),
      fileSelected: false,
      settings: [
        { value: null, text: 'Default' },
        { value: 'manual-percent', text: 'Manual (percent)' },
        { value: 'manual-value', text: 'Manual (value)' }
      ],
      upRegulatedGenes: null,
      downRegulatedGenes: null,
      geneAnnotation: '',
      input: {},
      geneAnnotations: ['gene_symbol', 'kegg_id'],
      loading: false
    };
  },
  computed: {
    canSubmit: function() {
      return this.fileSelected && this.geneAnnotation !== '';
    },
    hasSampleFile: function() {
      return !!this.config.sample_file;
    }
  },
  mounted() {
    this.checkLoggedIn(this.$session);
    const self = this;
    this.api.getMLModuleByName(
      'Pathway Prediction',
      function(module) {
        self.config = JSON.parse(module.config);
      },
      function(error) {
        console.log(error);
      }
    );
  },
  methods: {
    onChange(...args) {
      const [name, value] = args;
      this.parameters[name] = value;
    },
    onSetFile(...args) {
      const [name, file] = args;
      if (file) {
        this.files[name] = file;
        this.fileSelected = true;
        this.input[name] = file.name;
      } else {
        this.fileSelected = false;
      }
    },
    isParametersValid() {
      return !((this.upRegulatedGenes === 'manual-percent' && this.downRegulatedGenes === 'manual-value') ||
          (this.upRegulatedGenes === 'manual-value' && this.downRegulatedGenes === 'manual-percent'))
    },
    prepareParameters() {
      const isDefault = !this.upRegulatedGenes && !this.downRegulatedGenes;
      const { top, higherThan, bottom, lowerThan } = this.parameters;
      let params = {};
      if (isDefault) {
        params = { percent: true, up: 5.0, down: 5.0 };
      } else if (top && bottom) {
        params = { percent: true, up: top, down: bottom };
      } else if (higherThan && lowerThan) {
        params = { percent: false, up: higherThan, down: lowerThan };
      }
      params.genetype = this.geneAnnotation;
      return params;
    },
    onSubmit(visibility) {
      this.loading = true;
      this.$session.start();
      const self = this;
      this.input = {
        ...this.input,
        'Up-regulated genes': this.settings.find(option => option.value === this.upRegulatedGenes).text,
        'Top (percent)': this.parameters.top || null,
        'Higher than': this.parameters.higherThan || null,
        'Down-regulated genes': this.settings.find(option => option.value === this.downRegulatedGenes).text,
        'Bottom (percent)': this.parameters.bottom || null,
        'lower than': this.parameters.lowerThan || null,
        'Gene Annotation': this.geneAnnotation
      };
      this.api.executeMLModule(
        { name: 'Pathway Prediction' },
        { ...self.prepareParameters(), ...visibility },
        self.files,
        function(response) {
          router.push({
            name: 'PathwayPredictionResultView',
            params: { id: response.id }
          });
          self.loading = false;
        },
        function(error) {
          console.log(error);
          self.loading = false;
        },
        this.input
      );
    },
    validate() {
      if (this.isParametersValid()) {
        return true;
      } else {
        Swal.fire({
          icon: 'error',
          text: `Parameter can be input as "Top and Bottom"
                           or "Higher Than and Lower Than"`,
          showConfirmButton: true,
          showCloseButton: false
        });
        return false;
      }
    }
  }
};
</script>

<style scoped>
</style>
